import React, { FC } from "react";
import { Typography } from "@mui/material";

import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { ConfirmModal } from "../../../../../global/components/Modals/ConfirmModal/ConfirmModal";
import { IPatientFamilyMember } from "../../../../../global/domains/patients/types/IPatientFamilyMember.interface";
import { IConfirmationModalProps } from "./types/IConfirmationModalProps";

const ConfirmationModal: FC<IConfirmationModalProps> = ({
  isOpen,
  onClose,
  onPrimaryButtonClick,
  familyMembersWithoutForms,
  familyMembersWithForms,
}): JSX.Element => {
  const familyNames: JSX.Element[] = (familyMembersWithoutForms ?? []).map(
    (familyMember: IPatientFamilyMember) => {
      return (
        <Typography variant="h6" key={familyMember.id}>
          - {familyMember.firstName}
        </Typography>
      );
    }
  );

  const warningMessage = `Are you sure you want to send forms to just ${
    (familyMembersWithForms ?? []).length
  } patient${(familyMembersWithForms ?? []).length > 1 ? "s" : ""}?`;

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onPrimaryButtonClick={onPrimaryButtonClick}
      title="Family Check"
      subtitle=""
    >
      <Typography variant="body2" paddingBottom={2}>
        It looks like multiple family members are here and you haven&apos;t
        check if the patient(s) below need forms
      </Typography>
      {familyNames}
      <Typography variant="body2" paddingTop={2}>
        {warningMessage}
      </Typography>
    </ConfirmModal>
  );
};

export const ConfirmFamilyMembersWithoutFormsModal = React.memo(
  ConfirmationModal,
  areEqualProps
);
