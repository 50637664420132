import { createApi } from "@reduxjs/toolkit/query/react";

import ITextTemplate from "../../../types/settings/templates/ITextTemplate";
import { ICustomerPracticeLocations } from "../../../types/practice/ICustomerPracticeLocations";
import {
  IPracticeInformation,
  IReturnPracticeInformation,
} from "../../../types/practice/IPracticeInformation";
import {
  IPracticeLogo,
  IReturnPracticeLogo,
} from "../../../types/practice/IPracticeLogo";
import IGenericApiResponse from "../../../types/services/api/IGenericApiResponse";
import { IGetScheduleAppointments } from "../../../types/appointments/IGetScheduleAppointments.interface";
import { IPmsAppointmentConfirmationStatuses } from "../../../types/pms/IPmsAppointmentConfirmationStatuses";
import { IGetScheduleAppointmentsParams } from "../../../types/appointments/IGetScheduleAppointmentsParams.interface";
import { baseQueryWithReAuth } from "../../../helperFunctions/api/baseQueryWithReauth";
import { ICategoryTextTemplate } from "../../../../features/textTemplate/ICategoryTextTemplate";
import {
  getFormNameFromApiResponse,
  getNPWMergeTagId,
  getNPWMergeTagNameFromBody,
  getPackageNameFromApiResponse,
  updateNPWState,
} from "../../../../features/textTemplate/templateHelpers";
import { FormResponse } from "./formLibrary/formEditor/formResponse.interface";
import { IFormPackage } from "../../../../features/settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import {
  IReviewRequestConfiguration,
  IReviewRequestExclusionsPut,
  ReviewRequestExclusions,
} from "../../../../features/settings/practiceSettings/reviews/IReviews";

const DATAFORMS_HOST = process.env.REACT_APP_YAPI_DATAFORMS;

interface ITextTemplateAdditionalProps {
  npwMergeTag?: string;
  npwFormId?: number;
  npwPackageId?: string;
}

export const practiceApi = createApi({
  reducerPath: "practiceApi",
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  tagTypes: [
    "TextTemplate",
    "PracticeLogo",
    "PracticeInformation",
    "AppointmentStatuses",
    "ReviewRequestConfigurationDetails",
    "ReviewRequestExclusions",
  ],
  endpoints: (builder) => ({
    getCustomerPracticeLocations: builder.query<
      ICustomerPracticeLocations,
      void
    >({
      query: () => "login/CustomerPracticeLocations",
    }),
    setSelectedPracticeLocation: builder.mutation<
      { practiceId: string },
      string
    >({
      query(guid) {
        return {
          url: "login/CustomerPracticeLocations/Current",
          method: "PUT",
          body: {
            practiceId: guid,
          },
        };
      },
    }),
    getPracticeInformation: builder.query<IReturnPracticeInformation, null>({
      query: () => `Practices/Current`,
      providesTags: ["PracticeInformation"],
    }),
    setPracticeInformation: builder.mutation<
      IReturnPracticeInformation,
      { guid: string; practiceInformation: IPracticeInformation }
    >({
      query({ guid, practiceInformation }) {
        return {
          url: `Practices/${guid}`,
          method: "PUT",
          body: practiceInformation,
        };
      },
      invalidatesTags: ["PracticeInformation"],
    }),
    getPracticeLogo: builder.query<
      { logo: string; practiceId: string },
      { guid: string; size: string }
    >({
      query: ({ guid, size }) => `PracticeLogos/${guid}?Size=${size}`,
      providesTags: ["PracticeLogo"],
    }),
    setPracticeLogo: builder.mutation<
      IReturnPracticeLogo,
      { guid: string; practiceLogo: IPracticeLogo }
    >({
      query({ guid, practiceLogo }) {
        return {
          url: `PracticeLogos/${guid}`,
          method: "PUT",
          body: practiceLogo,
        };
      },
      invalidatesTags: ["PracticeLogo"],
    }),
    deletePracticeLogo: builder.mutation<string, string>({
      query(guid) {
        return {
          url: `PracticeLogos/${guid}`,
          method: "DELETE",
        };
      },
    }),
    getPracticeAvaliableTwilioPhone: builder.mutation<string[], string>({
      query(codeArea) {
        return {
          url: `AvailableTwilioPhoneNumbers/?Type=ADP10&AreaCode=${codeArea}&Size=10&CountryCode=US`,
        };
      },
    }),
    setPracticeTwilioConfiguration: builder.mutation<
      string[],
      { practiceId: string; phone: string }
    >({
      query(requestOpt) {
        return {
          url: `TextMessageConfigurations/${requestOpt.practiceId}`,
          body: {
            phone: requestOpt.phone,
          },
          method: "PUT",
        };
      },
    }),
    getPracticeTextTemplate: builder.query<
      IGenericApiResponse<ITextTemplate>,
      number
    >({
      query(pageIndex) {
        return {
          url: `PracticeTextMessageTemplates/?PageIndex=${pageIndex}&PageSize=5`,

          method: "GET",
        };
      },
    }),

    getTextTemplateByCategory: builder.query<ICategoryTextTemplate[], string>({
      query(categoryType) {
        return {
          url: `textTemplates/?categoryType=${categoryType}`,

          method: "GET",
        };
      },
      providesTags: ["TextTemplate"],
    }),
    createPracticeTextTemplate: builder.mutation<
      ITextTemplate,
      { name: string; body: string }
    >({
      query({ name, body }) {
        return {
          url: `PracticeTextMessageTemplates`,
          method: "POST",
          body: {
            name,
            body,
          },
        };
      },
      invalidatesTags: [{ type: "TextTemplate", id: "LIST" }],
    }),
    updateTextTemplate: builder.mutation<
      ITextTemplate,
      { name: string; body: string; templateId: string }
    >({
      query({ name, body, templateId }) {
        return {
          url: `PracticeTextMessageTemplates/${templateId}`,
          method: "PUT",
          body: {
            name,
            body,
          },
        };
      },
      invalidatesTags: ["TextTemplate"],
    }),
    getTextTemplates: builder.query<
      IGenericApiResponse<ITextTemplate[]>,
      { sortField?: string; sortOrder?: number }
    >({
      query({ sortField = "dateUpdated", sortOrder = 1 }) {
        return {
          url: `PracticeTextMessageTemplates?SortField=${sortField}&SortOrder=${sortOrder}&PageSize=2000`,
        };
      },
      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: "TextTemplate" as const,
                id,
              })),
              { type: "TextTemplate", id: "LIST" },
            ]
          : [{ type: "TextTemplate", id: "LIST" }];
      },
    }),
    getTextTemplateById: builder.query<any, string>({
      async queryFn(templateId, api, extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: `PracticeTextMessageTemplates/${templateId}`,
          method: "GET",
        });

        const data = response?.data as ITextTemplate;
        if (!data?.body) return response;

        const additionalInfo: ITextTemplateAdditionalProps = {};
        const npwTag: string = getNPWMergeTagNameFromBody(data.body);

        if (npwTag) {
          additionalInfo.npwMergeTag = npwTag;
          const id: string = getNPWMergeTagId(npwTag);
          const isFormId = !!+id;
          if (isFormId) {
            additionalInfo.npwFormId = +id;
            const formResponse = await fetchWithBQ({
              url: DATAFORMS_HOST + `/Form/latest/${id}`,
              method: "GET",
            });
            const data = formResponse?.data as FormResponse;
            const error = formResponse?.error;
            const name: string = getFormNameFromApiResponse(data, error);
            updateNPWState({
              dispatch: api.dispatch,
              newPatientWelcomeTag: npwTag,
              formOrPackageName: name,
              formOrPackageId: +id,
              setMergeTagMap: undefined,
            });
          } else {
            additionalInfo.npwPackageId = id;
            const packageResponse = await fetchWithBQ({
              url: DATAFORMS_HOST + `/packages/${id}`,
              method: "GET",
            });
            const data = packageResponse?.data as IFormPackage;
            const error = packageResponse?.error;
            const name: string = getPackageNameFromApiResponse(data, error);
            updateNPWState({
              dispatch: api.dispatch,
              newPatientWelcomeTag: npwTag,
              formOrPackageName: name,
              formOrPackageId: id,
              setMergeTagMap: undefined,
            });
          }
        }

        return { data: { ...data, ...additionalInfo } };
      },
      providesTags: (result, error, id) => [{ type: "TextTemplate", id }],
    }),
    deleteTextTemplate: builder.mutation<string, string>({
      query(templateId) {
        return {
          url: `PracticeTextMessageTemplates/${templateId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["TextTemplate"],
    }),
    getScheduledAppointments: builder.query<
      IGetScheduleAppointments,
      IGetScheduleAppointmentsParams
    >({
      query(params) {
        return {
          url: "/ScheduledAppointments",
          params,
          method: "GET",
        };
      },
    }),
    getAppointmentConfirmationStatuses: builder.query<
      IPmsAppointmentConfirmationStatuses[],
      string
    >({
      query: (param) =>
        `AppointmentConfirmationStatuses?AppointmentConfirmationStatusCategories=${param}`,
      providesTags: ["AppointmentStatuses"],
    }),
    setAppointmentConfirmationStatuses: builder.mutation<any, any>({
      query(formData) {
        return {
          url: "AppointmentConfirmationStatuses",
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["AppointmentStatuses"],
    }),
    getReviewRequestsConfigurationDetails: builder.query<
      IReviewRequestConfiguration,
      void
    >({
      query: () => "ReviewRequestConfigurationDetails/current",
      providesTags: ["ReviewRequestConfigurationDetails"],
    }),
    setReviewRequestsConfigurationDetails: builder.mutation<
      any,
      IReviewRequestConfiguration
    >({
      query: (data) => {
        return {
          url: "ReviewRequestConfigurationDetails/current",
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["ReviewRequestConfigurationDetails"],
    }),
    getReviewRequestExclusions: builder.query<ReviewRequestExclusions, void>({
      query: () => "ReviewRequestExclusions/",
      providesTags: ["ReviewRequestExclusions"],
    }),

    setReviewRequestExclusions: builder.mutation<
      any,
      IReviewRequestExclusionsPut
    >({
      query: (data) => {
        return {
          url: "ReviewRequestExclusions/",
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["ReviewRequestExclusions"],
    }),
  }),
});

export const {
  useGetCustomerPracticeLocationsQuery,
  useLazyGetCustomerPracticeLocationsQuery,
  useSetSelectedPracticeLocationMutation,
  useGetPracticeInformationQuery,
  useLazyGetPracticeInformationQuery,
  useSetPracticeInformationMutation,
  useSetPracticeLogoMutation,
  useGetPracticeLogoQuery,
  useDeletePracticeLogoMutation,
  useGetPracticeAvaliableTwilioPhoneMutation,
  useSetPracticeTwilioConfigurationMutation,
  useGetPracticeTextTemplateQuery,
  useCreatePracticeTextTemplateMutation,
  useGetTextTemplatesQuery,
  useDeleteTextTemplateMutation,
  useGetTextTemplateByIdQuery,
  useUpdateTextTemplateMutation,
  useGetScheduledAppointmentsQuery,
  useGetAppointmentConfirmationStatusesQuery,
  useSetAppointmentConfirmationStatusesMutation,
  usePrefetch,
  useGetTextTemplateByCategoryQuery,
  useGetReviewRequestsConfigurationDetailsQuery,
  useSetReviewRequestsConfigurationDetailsMutation,
  useGetReviewRequestExclusionsQuery,
  useSetReviewRequestExclusionsMutation,
} = practiceApi;
