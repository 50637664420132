import { createApi } from "@reduxjs/toolkit/query/react";
import {
  IMassText,
  IMassTextMessages,
  IPatientText,
} from "../../types/communications/IMassText";
import { queryStringBuilder } from "../../helperFunctions/api/queryStringBuilder";
import { IPatientTextMessageResponse } from "../patients/patientsText/IPatientTextMessageResponse";
import { IMassTextMessageHistoryResponse } from "../../types/communications/IMassTextMessageHistoryResponse";
import {
  IMassTextMessageParams,
  IPatientTextMessageParams,
} from "../../types/communications/IMassTextMessageParams.interface";
import { IPaging } from "../../types/apiResponse/Paging";
import {
  IPatientHeader,
  IPatientTextMessage,
} from "../../domains/patients/types/IPatientTextMessage";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";

const MATCHING_TEXT_MESSAGE_PHONE_NUMBER = "MatchingTextMessagePhoneNumber";
const PATIENT_ID = "PatientId";
const MASS_MESSAGE_GROUP_ID = "GroupId";
export const communicationsApi = createApi({
  reducerPath: "communicationsApi",
  tagTypes: [
    MATCHING_TEXT_MESSAGE_PHONE_NUMBER,
    PATIENT_ID,
    MASS_MESSAGE_GROUP_ID,
  ],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_API_HOST),
  endpoints: (builder) => ({
    getMassTextMessages: builder.query<
      { data: IMassText[]; page: IPaging },
      IMassTextMessageParams
    >({
      query: (query) => {
        const queryParams = queryStringBuilder(query).toString();
        return `/matchingTextMessages?${queryParams}`;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: (result) => {
        // is result available?
        if (result) {
          return [
            ...result.data.map(({ from, to }) => [
              { type: MATCHING_TEXT_MESSAGE_PHONE_NUMBER, from } as const,
              { type: MATCHING_TEXT_MESSAGE_PHONE_NUMBER, to } as const,
            ]),
          ].flat();
        }
        return [];
      },
    }),
    getPatientTextConversations: builder.query<
      { data: IPatientText[]; page: IPaging },
      IPatientTextMessageParams
    >({
      query: (query) => {
        const getQuery = (query) => {
          if (query.value) {
            return query;
          } else {
            const {
              value: _value,
              criteria: _criteria,
              ...nonNullQuery
            } = query;
            return nonNullQuery;
          }
        };
        const nonNullQuery = getQuery(query);
        const queryParams = queryStringBuilder(nonNullQuery).toString();
        return `/TextMessageConversations?${queryParams}`;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: (result) => {
        // is result available?
        if (result) {
          return [
            ...result.data.map(({ from, to }) => [
              { type: MATCHING_TEXT_MESSAGE_PHONE_NUMBER, from } as const,
              { type: MATCHING_TEXT_MESSAGE_PHONE_NUMBER, to } as const,
            ]),
          ].flat();
        }
        return [];
      },
    }),
    getMassTextMessagesById: builder.query({
      query: (massTextMessageId) => {
        return `/MassTextMessages/${massTextMessageId}`;
      },
      extraOptions: { maxRetries: 3 },
    }),
    getImageAttachmentThumbnails: builder.query({
      query: (textMessageId) => {
        return `/TextMessages/${textMessageId}/thumbNails`;
      },
      extraOptions: { maxRetries: 3 },
    }),
    getFullSizeThumbnailImage: builder.query<
      { blob: Blob; headers: Headers },
      string
    >({
      query: (textMessageId) => ({
        Accept: "image/*",
        url: `/TextMessageAttachments/${textMessageId}`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return { blob, headers: response.headers };
        },
        cache: "no-store",
      }),
      extraOptions: { maxRetries: 3 },
    }),
    getPatientTextMessages: builder.query<
      IPatientTextMessageResponse,
      { patientId: string; pageSize?: number; pageIndex?: number }
    >({
      query: (requestOptions) => {
        const queryParams = queryStringBuilder(requestOptions).toString();
        return `TextMessages?${queryParams}`;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: (result, error, { patientId }) => {
        return [{ type: PATIENT_ID, patientId }];
      },
    }),
    getPatientTextHeaderMessages: builder.query<IPatientHeader, string>({
      query: (id) => {
        return `PatientTextMessages/${id}/header?useSproc=true`;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: (result, error, patientId) => {
        return [{ type: PATIENT_ID, patientId }];
      },
    }),
    getPatientTextBatchMessages: builder.query<IPatientTextMessage[], string>({
      query: (id) => {
        return `PatientTextMessages/${id}/stream?batchSize=1000`;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: (result, error, patientId) => {
        return [{ type: PATIENT_ID, patientId }];
      },
    }),
    getMassTextMessagesHistory: builder.query<
      IMassTextMessageHistoryResponse,
      { groupId: string }
    >({
      query: (requestOptions) => {
        return {
          url: `MassTextMessageHistories/?groupId=${requestOptions.groupId}`,
        };
      },
      providesTags: (result, error, { groupId }) => {
        if (result && !error) {
          return [{ type: MASS_MESSAGE_GROUP_ID, groupId }];
        }
      },
    }),
    setTextMessagesReads: builder.mutation<
      { phoneNumber: string; updatedTextMessagesCount: number },
      { phoneNumber: string; isRead: boolean }
    >({
      query(requestOptions) {
        return {
          url: `/TextMessagesReads/?phoneNumber=${requestOptions.phoneNumber}`,
          method: "PUT",
          body: {
            isRead: requestOptions.isRead,
          },
        };
      },
    }),
    setMassTextMessages: builder.mutation<
      IMassTextMessages,
      {
        contextTypeId: string;
        massTextTypeId: string;
        body: string;
        contextIds?: string[];
        phoneNumbers: string[];
        groupId?: string;
      }
    >({
      query(requestOptions) {
        return {
          url: "/MassTextMessages",
          method: "POST",
          body: {
            contextTypeId: requestOptions.contextTypeId,
            massTextTypeId: requestOptions.massTextTypeId,
            body: requestOptions.body,
            contextIds: requestOptions.contextIds,
            phoneNumbers: requestOptions.phoneNumbers,
            groupId: requestOptions.groupId,
          },
        };
      },
      invalidatesTags: () => [{ type: MASS_MESSAGE_GROUP_ID }],
    }),
    setSendTextPatient: builder.mutation<
      { to: string },
      { to: string; body: string; contextId?: string }
    >({
      query(requestOptions) {
        return {
          url: "textmessages",
          method: "POST",
          body: {
            To: requestOptions.to,
            Body: requestOptions.body,
            ContextId: requestOptions.contextId,
            ContextTypeId: "Patient",
          },
        };
      },
    }),
    resendTextMessage: builder.mutation<unknown, string>({
      query(messageId) {
        return {
          url: `TextMessages/${messageId}/resends`,
          method: "POST",
        };
      },
      invalidatesTags: ({ phoneNumber }) => [
        { type: MATCHING_TEXT_MESSAGE_PHONE_NUMBER, phoneNumber },
      ],
    }),
  }),
});

export const {
  useGetMassTextMessagesQuery,
  useGetPatientTextMessagesQuery,
  useSetMassTextMessagesMutation,
  useSetSendTextPatientMutation,
  useSetTextMessagesReadsMutation,
  useResendTextMessageMutation,
  useGetMassTextMessagesHistoryQuery,
  useGetPatientTextConversationsQuery,
  useGetPatientTextHeaderMessagesQuery,
  useGetPatientTextBatchMessagesQuery,
  useGetImageAttachmentThumbnailsQuery,
  useLazyGetFullSizeThumbnailImageQuery,
  useGetFullSizeThumbnailImageQuery,
  useLazyGetImageAttachmentThumbnailsQuery,
  useLazyGetPatientTextConversationsQuery,
} = communicationsApi;
