export const getBaseRoute = (route: string) => {
  if (!route) return "";

  const specialCases = [
    {
      match: (route: string) => route.startsWith("/TextMessages"),
      process: (route: string) => {
        const parts = route.split("/");
        return parts.some((part) =>
          ["image", "thumbnail", "preview"].includes(part)
        )
          ? route
          : "/TextMessages";
      },
    },
    {
      match: (route: string) => route.startsWith("/dashboard"),
      process: () => "/dashboard",
    },
    {
      match: (route: string) =>
        route.startsWith(
          "/practiceSettings/patientReminders/textTemplates/compose"
        ) || route.startsWith("/textTemplates/compose"),
      process: () => "/textTemplates",
    },
    {
      match: (route: string) =>
        ["/patientProfile", "/patientFinder", "/preferences"].some((path) =>
          route.startsWith(path)
        ),
      process: () => "/patientFinder",
    },
    {
      match: (route: string) => route.startsWith("/document-manager/"),
      process: (route: string) => {
        const parts = route.split("/");
        return parts.some((part) =>
          ["image", "preview", "signature"].includes(part)
        )
          ? route
          : "/document-manager";
      },
    },
    {
      match: (route: string) =>
        ["/inviteNewUser", "/editUser", "/users"].some((path) =>
          route.startsWith(path)
        ),
      process: () => "/users",
    },
    {
      match: (route: string) => route.startsWith("/emailCampaigns"),
      process: () => "/emailCampaigns",
    },
    {
      match: (route: string) => route.startsWith("/practiceSettings"),
      process: () => "/practiceSettings",
    },
  ];

  for (const { match, process } of specialCases) {
    if (match(route)) return process(route);
  }

  return route.split("?")[0];
};

// this one is without dashboard [YAP-6716]
export const getBaseRouteNoDashboard = (route: string) => {
  if (!route) return "";
  if (route.startsWith("/TextMessages")) {
    return "/TextMessages";
  }
  if (
    route.startsWith(
      "/practiceSettings/patientReminders/textTemplates/compose"
    ) ||
    route.startsWith("/textTemplates/compose")
  ) {
    return "/textTemplates";
  }
  if (
    route.startsWith("/patientProfile") ||
    route.startsWith("/patientFinder") ||
    route.startsWith("/preferences")
  ) {
    return "/patientFinder";
  }
  if (route.startsWith("/document-manager/")) {
    const parts = route.split("/");
    if (
      parts.length &&
      (parts.includes("image") ||
        parts.includes("preview") ||
        parts.includes("signature"))
    ) {
      return route;
    } else {
      return "/document-manager";
    }
  }
  if (
    route.startsWith("/inviteNewUser") ||
    route.startsWith("/editUser") ||
    route.startsWith("/users")
  ) {
    return "/users";
  }
  if (route.startsWith("/emailCampaigns")) {
    return "/emailCampaigns";
  }
  if (route.startsWith("/practiceSettings")) return "/practiceSettings";
  return route.split("?")[0];
};
