import { styled } from "@mui/material";

export const SImageWrapper = styled("div")(() => ({
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 170,
  height: 170,
  "& img": {
    objectFit: "contain",
    overflow: "hidden",
    minWidth: 35,
  },
}));

export const SImageViewWrapper = styled("div")(() => ({
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  zIndex: 1000,
  "& img": {
    objectFit: "contain",
    maxWidth: "90%",
    maxHeight: "90%",
  },
}));

export const SImageSmallWrapper = styled("div")(() => ({
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 170,
  height: 170,
  overflow: "hidden",
  "& img": {
    objectFit: "contain",
    overflow: "hidden",
    minWidth: 35,
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));
