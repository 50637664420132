import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../hooks/useTypedRedux.hook";
import { selectIsMobile } from "../../../features/layout/layoutSlice";
import { styled } from "@mui/material";
import { useAppHistory } from "../../hooks/useAppHistory";

interface BaseTabsProps {
  sections: IBaseTab[];
  selectedWidth?: string;
  unselectedWidth?: string;
  currentTabIndex: number;
  setTabIndex: (newIndex: number) => void;
  className?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface IBaseTab {
  component: React.ReactElement | React.FunctionComponent;
  name: string;
  label: string;
  disabled?: boolean;
  icon?: JSX.Element;
  route?: string;
  noGutter?: boolean;
  hidden?: boolean;
}

const STab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== "hidden",
})(({ hidden }: { hidden?: boolean }) => ({
  borderRadius: "8px 8px 0px 0px",
  "&:hover": { backgroundColor: "accent.background", opacity: 1 },
  padding: 0,
  opacity: 1,
  display: hidden ? "none" : "flex",
  gap: 8,
}));

type TTabPanel = {
  index: number;
  hidden: boolean;
};

const STabPanel = styled("div", {
  shouldForwardProp: (prop) => prop !== "index",
})<TTabPanel>(({ index, hidden }) => ({
  ariaLabelledBy: `simple-tab-${index}`,
  display: hidden ? "none" : "flex",
  height: "100%",
}));
const renderComponent = (component: any) => {
  if (typeof component === "function") {
    return React.createElement(component as React.FunctionComponent);
  } else if (React.isValidElement(component)) {
    return component;
  }
  return null;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <STabPanel
      index={index}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </STabPanel>
  );
}

const tabSelect = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index} `,
  };
};

export const BaseTabs: React.FC<BaseTabsProps> = ({
  currentTabIndex,
  setTabIndex,
  sections,
  selectedWidth,
  unselectedWidth,
  className,
}: BaseTabsProps) => {
  const history = useAppHistory();
  const isMobile = useAppSelector(selectIsMobile);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    const { route } = sections[newValue];
    if (route) {
      history.push(route);
    }
  };

  const label = (label: string, selected: boolean) => {
    if (!isMobile || !sections[0]?.icon) return label;
    return selected ? label : "";
  };

  const tabSize = (selected: boolean) => {
    if (!isMobile) return;
    return selected ? selectedWidth : unselectedWidth;
  };

  return (
    <Box data-testid="baseTabs" height="100%" className={className}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        id="tabBox"
      >
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          variant="fullWidth"
          sx={{ height: "100%" }}
          textColor="inherit"
        >
          {sections.map((section, index) => (
            <STab
              data-testid="baseTabs/tab"
              key={`${section.name}/tab`}
              hidden={section.hidden}
              sx={{
                backgroundColor:
                  index === currentTabIndex && "accent.background",
                borderRadius: "8px 8px 0px 0px",
                "&:hover": { backgroundColor: "accent.background", opacity: 1 },
                minWidth: tabSize(index === currentTabIndex),
                minHeight: "50px",
                height: index === currentTabIndex ? "100%" : "0px",
              }}
              label={label(section.label, index === currentTabIndex)}
              icon={section.icon}
              iconPosition="start"
              {...tabSelect(index)}
              disabled={section.disabled}
            />
          ))}
        </Tabs>
      </Box>
      {sections.map((tab, index) => (
        <TabPanel
          value={currentTabIndex}
          index={index}
          key={`${tab.name + index}`}
        >
          <Box
            sx={{
              marginTop: sections[index].noGutter ? 0 : 2,
              flexGrow: 1,
              height: "100%",
              width: "100%",
            }}
          >
            {renderComponent(sections[index]?.component)}
          </Box>
        </TabPanel>
      ))}
    </Box>
  );
};
