import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../global/store";
import {
  IFormLibrarySlice,
  IFormUploadFailedModalOpen,
} from "./types/FormsLibrary.interfaces";
import { EOrderBy } from "../../../../global/enums/EOrderBy";
import { IFormLibraryQueryParams } from "../../../../global/types/settings/formBuilder/IFormLibraryQueryParams";

const initialState: IFormLibrarySlice = {
  deleteModalInformation: {
    isModalOpen: false,
    formId: null,
  },
  automationModalInformation: {
    isModalOpen: false,
    id: null,
    settings: null,
  },
  isWarningDeleteModalOpen: false,
  isActivateModalOpen: false,
  isActivateFormsModalOpen: false,
  activateFormsFormId: null,
  isExportErrorModalOpen: false,
  isWrongFormatFileModalOpen: false,
  isUploadLimitExceededModalOpen: false,
  isRefetchFormTable: false,
  formUploadFailedModalOpen: {
    isOpen: false,
    failedForms: [],
  },
  setPageCallBack: null,
  setSortingCallBack: null,
  filteringBy: {
    language: null,
    activeStatus: null,
    category: null,
  },
  deletePackageModalInformation: {
    isModalOpen: false,
    packageId: null,
    name: null,
  },
  shouldRefetchList: false,
  formsQueryParams: {
    page: 1,
    pageSize: 10,
    search: "",
    sorting: [{ field: "name", sort: EOrderBy.asc }],
    // filters
    languages: [],
    categories: [],
    statuses: [],
  },
};

const formLibrarySlice = createSlice({
  name: "formLibrarySlice",
  initialState,
  reducers: {
    setDeleteModalInformation: (state, action) => {
      state.deleteModalInformation = action.payload;
    },
    setIsActivateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isActivateModalOpen = action.payload;
    },
    setIsActivateFormsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isActivateFormsModalOpen = action.payload;
    },
    setActivateFormsFormId: (state, action: PayloadAction<number>) => {
      state.activateFormsFormId = action.payload;
    },
    setIsExportErrorModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isExportErrorModalOpen = action.payload;
    },
    setIsWrongFormatFileModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWrongFormatFileModalOpen = action.payload;
    },
    setIsWarningDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWarningDeleteModalOpen = action.payload;
    },
    setIsUploadLimitExceededModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isUploadLimitExceededModalOpen = action.payload;
    },
    setIsRefetchFormTable: (state, action: PayloadAction<boolean>) => {
      state.isRefetchFormTable = action.payload;
    },
    setFormUploadFailedModalOpen: (
      state,
      action: PayloadAction<IFormUploadFailedModalOpen>
    ) => {
      state.formUploadFailedModalOpen = action.payload;
    },
    setPageCallBack: (state, action) => {
      state.setPageCallBack = action.payload;
    },
    setSortingCallBack: (state, action) => {
      state.setSortingCallBack = action.payload;
    },
    setFilteringBy: (state, action) => {
      state.filteringBy = action.payload;
    },
    resetFilters: (state) => {
      state.filteringBy = initialState.filteringBy;
    },
    setDeletePackageModalInformation: (state, action) => {
      state.deletePackageModalInformation = action.payload;
    },
    updateShouldRefetchList: (state, action: PayloadAction<boolean>) => {
      state.shouldRefetchList = action.payload;
    },
    setAutomationModalInformation: (state, action) => {
      state.automationModalInformation = action.payload;
    },
    resetAutomationModalInformation: (state) => {
      state.automationModalInformation =
        initialState.automationModalInformation;
    },
    updateFormsQueryParams: (
      state: IFormLibrarySlice,
      action: PayloadAction<Partial<IFormLibraryQueryParams>>
    ): void => {
      state.formsQueryParams = { ...state.formsQueryParams, ...action.payload };
    },
    setDefaultFormsQueryParams: (state: IFormLibrarySlice): void => {
      state.formsQueryParams = {
        page: 1,
        pageSize: 10,
        search: "",
        sorting: [{ field: "name", sort: EOrderBy.asc }],
        languages: [],
        categories: [],
        statuses: [],
      };
    },
  },
});

export const {
  setDeleteModalInformation,
  setIsActivateModalOpen,
  setIsActivateFormsModalOpen,
  setActivateFormsFormId,
  setIsExportErrorModalOpen,
  setPageCallBack,
  setSortingCallBack,
  setFilteringBy,
  resetFilters,
  setDeletePackageModalInformation,
  updateShouldRefetchList,
  setIsWrongFormatFileModalOpen,
  setIsUploadLimitExceededModalOpen,
  setFormUploadFailedModalOpen,
  setIsRefetchFormTable,
  setIsWarningDeleteModalOpen,
  setAutomationModalInformation,
  resetAutomationModalInformation,
  updateFormsQueryParams,
  setDefaultFormsQueryParams,
} = formLibrarySlice.actions;

export const selectDeleteFormIsModalOpen = (state: RootState) =>
  state.formLibraryState.deleteModalInformation.isModalOpen;

export const selectAutomationModalIsModalOpen = (state: RootState) =>
  state.formLibraryState.automationModalInformation?.isModalOpen;

export const selectActivateFormsModalIsModalOpen = (state: RootState) =>
  state.formLibraryState.isActivateFormsModalOpen;

export const selectActivateFormsFormId = (state: RootState) =>
  state.formLibraryState.activateFormsFormId;

export const selectAutomationModalSettings = (state: RootState) =>
  state.formLibraryState.automationModalInformation?.settings;

export const selectAutomationModalId = (state: RootState) =>
  state.formLibraryState.automationModalInformation?.id;

export const selectDeletePackageIsModalOpen = (state: RootState) =>
  state.formLibraryState.deletePackageModalInformation.isModalOpen;

export const selectFormId = (state: RootState) =>
  state.formLibraryState.deleteModalInformation.formId;

export const selectPackageId = (state: RootState) =>
  state.formLibraryState.deletePackageModalInformation.packageId;

export const selectPackageName = (state: RootState) =>
  state.formLibraryState.deletePackageModalInformation.name;

export const selectFilteringBy = (state: RootState) =>
  state.formLibraryState.filteringBy;

export const selectShouldRefetchList = (state: RootState) =>
  state.formLibraryState.shouldRefetchList;

export const selectSetPageCallBack = (state: RootState) =>
  state.formLibraryState.setPageCallBack;

export const selectSetSortingCallBack = (state: RootState) =>
  state.formLibraryState.setSortingCallBack;

export const selectIsActivateModalOpen = (state: RootState) =>
  state.formLibraryState.isActivateModalOpen;

export const selectIsExportErrorModalOpen = (state: RootState): boolean =>
  state.formLibraryState.isExportErrorModalOpen;

export const selectIsWrongFormatFileModalOpen = (state: RootState): boolean =>
  state.formLibraryState.isWrongFormatFileModalOpen;

export const selectIsWarningDeleteModalOpen = (state: RootState): boolean =>
  state.formLibraryState.isWarningDeleteModalOpen;

export const selectIsUploadLimitExceededModalOpen = (
  state: RootState
): boolean => state.formLibraryState.isUploadLimitExceededModalOpen;

export const selectIsRefetchFormTable = (state: RootState): boolean =>
  state.formLibraryState.isRefetchFormTable;

export const selectFormUploadFailedModalOpen = (
  state: RootState
): IFormUploadFailedModalOpen =>
  state.formLibraryState.formUploadFailedModalOpen;

export const selectFormsQueryParams = (
  state: RootState
): IFormLibraryQueryParams => {
  return state.formLibraryState.formsQueryParams;
};

export default formLibrarySlice.reducer;
