import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../global/store";
import {
  IComposePatient,
  IMassText,
  IPatientText,
} from "../../global/types/communications/IMassText";
import {
  IMassTextMessageParams,
  IPatientTextMessageParams,
} from "../../global/types/communications/IMassTextMessageParams.interface";
import {
  defaultMassTextParams,
  defaultPatientTextParams,
} from "./utils/defaultTextParams";

interface ICommunicationState {
  status: string;
  error: string;
  composing: boolean;
  selectedMessage?: IMassText;
  selectedComposePatient?: IComposePatient;
  drafts: { [key: string]: string };
  optOutOverrides: { [key: string]: boolean };
  patientTextParams: IPatientTextMessageParams;
  massTextParams: IMassTextMessageParams;
  currentTab: number;
  patientConversationsList: IPatientText[];
  inViewTextMessages: { [key: string]: boolean };
}

const initialState: ICommunicationState = {
  status: "init",
  error: "",
  composing: false,
  drafts: {},
  optOutOverrides: {},
  patientTextParams: defaultPatientTextParams,
  massTextParams: defaultMassTextParams,
  patientConversationsList: [],
  currentTab: 0,
  inViewTextMessages: {},
};

export const communicationSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    updateInViewTextMessages: (
      state: ICommunicationState,
      { payload }: PayloadAction<{ [key: string]: boolean }>
    ) => {
      state.inViewTextMessages = {
        ...state.inViewTextMessages,
        ...payload,
      };
    },
    updateComposing: (
      state: ICommunicationState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.composing = payload;
    },
    updateSelectedMessage: (
      state: ICommunicationState,
      { payload }: PayloadAction<IMassText>
    ) => {
      state.selectedMessage = payload;
    },
    updateSelectedComposePatient: (
      state: ICommunicationState,
      { payload }: PayloadAction<IComposePatient>
    ) => {
      state.selectedComposePatient = payload;
    },
    clearComposePatient: (state: ICommunicationState) => {
      state.selectedComposePatient = null;
    },
    updateDraft: (
      state,
      action: PayloadAction<{ contextId: string; draft: string }>
    ) => {
      state.drafts = {
        ...state.drafts,
        [action.payload.contextId]: action.payload.draft,
      };
    },
    updateOptOutOverrides: (
      state,
      action: PayloadAction<{ patientId: string; optOutOverride: boolean }>
    ) => {
      state.optOutOverrides = {
        ...state.optOutOverrides,
        [action.payload.patientId]: action.payload.optOutOverride,
      };
    },
    updateTab: (state, { payload }: PayloadAction<number>) => {
      state.currentTab = payload;
    },
    updatePatientTextParams: (
      state,
      { payload }: PayloadAction<IPatientTextMessageParams>
    ) => {
      state.patientTextParams = payload;
    },
    resetPatientParams: (state) => {
      state.patientTextParams = initialState.patientTextParams;
    },
    updateMassTextParams: (
      state,
      { payload }: PayloadAction<IMassTextMessageParams>
    ) => {
      state.massTextParams = payload;
    },
    updatePatientConversationsList: (
      state,
      { payload }: PayloadAction<IPatientText[]>
    ) => {
      state.patientConversationsList = payload;
    },
  },
});

export const {
  updateComposing,
  updateDraft,
  updateSelectedMessage,
  updateSelectedComposePatient,
  clearComposePatient,
  updateOptOutOverrides,
  updateTab,
  updatePatientTextParams,
  updateMassTextParams,
  resetPatientParams,
  updatePatientConversationsList,
  updateInViewTextMessages,
} = communicationSlice.actions;

export const selectInViewTextMessages = (state: RootState) =>
  state.communicationState.inViewTextMessages;
export const selectedMessage = (state: RootState) =>
  state.communicationState.selectedMessage;
export const selectedComposePatient = (state: RootState) =>
  state.communicationState.selectedComposePatient;
export const selectIsComposing = (state: RootState) =>
  state.communicationState.composing;
export const selectDrafts = (state: RootState) =>
  state.communicationState.drafts;
export const selectOptOutOverrides = (state: RootState) =>
  state.communicationState.optOutOverrides;
export const selectCurrentTab = (state: RootState) =>
  state.communicationState.currentTab;
export const selectPatientTextParams = (state: RootState) =>
  state.communicationState.patientTextParams;
export const selectMassTextParams = (state: RootState) =>
  state.communicationState.massTextParams;
export const selectPatientConversationsList = (state: RootState) =>
  state.communicationState.patientConversationsList;
export default communicationSlice.reducer;
