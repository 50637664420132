import { union } from "lodash";
import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../../helperFunctions/api/baseQueryWithReauth";
import { EFeatureFlags } from "../../enums/EFeatureFlags";

export const featureFlagsApi = createApi({
  reducerPath: "featureFlagsApi",
  tagTypes: ["featureFlags"],
  baseQuery: baseQueryWithReAuth(
    process.env.REACT_APP_API_HOST.replace("/v1/", "/")
  ),
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<EFeatureFlags[], void>({
      async queryFn(args, api, extraOptions, fetchWithBQ) {
        try {
          const [v1Response, v2Response] = await Promise.all([
            fetchWithBQ({
              url: `v1/FeatureManagement/EnabledFeatures`,
              method: "GET",
            }),
            fetchWithBQ({
              url: `v2/FeatureManagement/EnabledFeatures`,
              method: "GET",
            }),
          ]);

          // Handle cases only if both fail
          if (v1Response.error && v2Response.error) {
            return { error: v1Response.error || v2Response.error };
          }

          const featureFlagsForV1: EFeatureFlags[] = Array.isArray(
            v1Response.data
          )
            ? (v1Response.data as EFeatureFlags[])
            : [];
          const featureFlagsForV2: EFeatureFlags[] = Array.isArray(
            v2Response.data
          )
            ? (v2Response.data as EFeatureFlags[])
            : [];

          return { data: union(featureFlagsForV1, featureFlagsForV2) };
        } catch (error) {
          const fetchError = error as FetchBaseQueryError;
          // eslint-disable-next-line no-console
          console.error("Unexpected Error fetching feature flags:", error);
          return {
            error: {
              status: fetchError.status || "Error getting feature flags",
              data:
                fetchError.data ||
                "An unknown error occurred while fetching feature flags",
            } as FetchBaseQueryError,
          };
        }
      },
      providesTags: ["featureFlags"],
    }),
  }),
});

export const { useLazyGetFeatureFlagsQuery, useGetFeatureFlagsQuery } =
  featureFlagsApi;
