import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { apiHeaders } from "./apiHeaders";
import { sec } from "./security";
import { RootState } from "../../store";
import { IContentType } from "../../types/settings/formBuilder/IContentType.interface";

const getBaseQuery = (baseUrl: string, contentType: IContentType) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: "include",
    prepareHeaders: async (headers, { getState }) => {
      const getAccessTokenSilently = sec.getAccessTokenSilently();
      const token = await getAccessTokenSilently();
      const guid = (getState() as RootState).appState.api.practiceGuid;
      const sessionId = (getState() as RootState).appState.sessionId;
      const headerInfo = {
        auth0Token: token,
        practiceGuid: guid,
      };
      apiHeaders(headers, headerInfo, contentType, sessionId);
      return headers;
    },
  });
const ErrorList = [401, 403] as FetchBaseQueryError["status"][];
export const baseQueryWithReAuth = (
  baseURL: string,
  contentType?: IContentType
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
  return async (args, api, extraOptions) => {
    const baseQuery = getBaseQuery(baseURL, contentType);
    let result = await baseQuery(args, api, extraOptions);

    if (!result.error) return result;

    const isAuthError = (error: FetchBaseQueryError) =>
      ErrorList.includes(error?.status);

    const retryWithRefresh = async () => {
      const maxRetries = 3;
      for (let attempt = 0; attempt < maxRetries; attempt++) {
        if (!isAuthError(result.error)) break;

        try {
          await sec.getAccessTokenSilently();
          result = await baseQuery(args, api, extraOptions);

          if (!result.error) return result;
        } catch (refreshError) {
          // eslint-disable-next-line no-console
          console.error("Token refresh failed:", refreshError);
          return {
            error: {
              status: 401,
              data: "Failed to refresh token",
              refreshError:
                refreshError instanceof Error
                  ? refreshError.message
                  : String(refreshError),
            },
          };
        }
      }
      return result;
    };

    return await retryWithRefresh();
  };
};
