import React from "react";
import { styled } from "@mui/system";

import { escapeSpecialCharacters } from "../../global/helperFunctions/text/escapeSpecialCharacters";
import { replaceSpecialHTMLSymbols } from "../../global/helperFunctions/text/replaceSpecialHTMLSymbols";
import mergeTagIcon from "../../assets/merge_tag_icon.svg";
import {
  MergeTagMap,
  REVIEWS_REQUEST_TAG,
  USE_LINK_TO_SCHEDULE_MERGE_TAG,
} from "./mergeTags";
import { EActiveStatus } from "../../global/enums/EActiveStatus";
import { EPackageStatus } from "../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/EPackageStatus";

import {
  INewPatientWelcome,
  updateNewPatientWelcomeData,
} from "./textTemplateSlice";
import { removeHTML } from "../../global/helperFunctions/text/removeHTML";

//--------------------------New Patient Welcome------------------------------//

export const SCustomMergeTagHighlight = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.accent.background,
  borderRadius: 3,
  border: `1px solid ${theme.palette.primary.main}`,
  padding: 0,
  lineHeight: "1.25rem",
  marginTop: "1rem",

  "& > span": {
    marginRight: "0.5rem",
  },
}));

// mask for form and package tags
// [[NPW-Form | Select the link to complete your paperwork: | FormID ]]
// [[NPW-Package | Select the link to complete your paperwork: | PackageID ]]

// npw means New Patient Welcome

export const npwFormRegex = /\[\[NPW-Form \|.*?]]/;
export const npwPackageRegex = /\[\[NPW-Package \|.*?]]/;

export const tagDescription = "Select the link to complete your paperwork:";
export const npwIconDescription = "New Patient Welcome Icon";

export const npwFormMergeTagNameWithoutId = `[[NPW-Form | ${tagDescription} |`;
export const npwPackageMergeTagNameWithoutId = `[[NPW-Package | ${tagDescription} |`;
export const npwMergeTagNameMapper = {
  number: npwFormMergeTagNameWithoutId,
  string: npwPackageMergeTagNameWithoutId,
};

export const newPatientWelcomeTagIconHTML = `<img src=${mergeTagIcon} style="transform: translateY(3px);" alt="New Patient Welcome Icon" />`;

export const prepareToSendNPWMergeTagName = (
  body: string,
  selectedTag
): string => {
  const regex = new RegExp(
    tagDescription + ".*?" + escapeSpecialCharacters(selectedTag?.name)
  );
  const formattedBody: string = replaceSpecialHTMLSymbols(body);

  if (regex.test(formattedBody)) {
    return formattedBody?.replace(
      regex,
      `${npwMergeTagNameMapper[typeof selectedTag?.id]} ${selectedTag?.id}]]`
    );
  }
  return body;
};

export const getNPWMergeTagText = (name: string): string => {
  return `${tagDescription} ${newPatientWelcomeTagIconHTML} ${name}`;
};

export const getCustomMergeTagTextComponent = (name: string) => {
  return function CustomMergeTag(): JSX.Element {
    return (
      <SCustomMergeTagHighlight>
        {tagDescription}
        <span
          dangerouslySetInnerHTML={{
            __html: "<br>" + newPatientWelcomeTagIconHTML,
          }}
        />
        {name}
      </SCustomMergeTagHighlight>
    );
  };
};

export const getNPWMergeTagNameFromBody = (body: string): string => {
  return (
    RegExp(npwFormRegex).exec(body)?.[0] ||
    RegExp(npwPackageRegex).exec(body)?.[0]
  );
};

export const getNPWMergeTagId = (npwTagName: string): string => {
  const idRegex = new RegExp(tagDescription + " " + "\\| (.*?)]]");
  return RegExp(idRegex).exec(npwTagName)?.[1];
};

export const checkIfItemWasDeleted = (error): string => {
  // according to contract between frontend and backend statusCode = 400 will be an identifier of deleted form/package
  if (error?.status === 400) {
    return "[Deleted]";
  }

  return null;
};

export const getFormNameFromApiResponse = (response, error): string => {
  let formName: string = checkIfItemWasDeleted(error);
  if (response?.id && !error) {
    const { name, activeStatus } = response;
    formName =
      activeStatus === EActiveStatus.Inactive ? `[Inactive] ${name}` : name;
  }

  return formName;
};

export const getPackageNameFromApiResponse = (response, error): string => {
  let packageName: string = checkIfItemWasDeleted(error);
  if (response?.id && !error) {
    const { name, status } = response;
    packageName =
      status === EPackageStatus.Inactive ? `[Inactive] ${name}` : name;
  }

  return packageName;
};
interface IUpdateNPWState {
  dispatch: any;
  newPatientWelcomeTag: string;
  formOrPackageName: string;
  formOrPackageId: number | string;
  setMergeTagMap?: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  isRelease_20250312?: boolean;
}
export const updateNPWState = ({
  dispatch,
  newPatientWelcomeTag,
  formOrPackageName,
  formOrPackageId,
  setMergeTagMap,
  isRelease_20250312,
}: IUpdateNPWState): void => {
  if (isRelease_20250312) {
    for (const key in MergeTagMap) {
      if (key.includes("[[NPW-Form") || key.includes("[[NPW-Package"))
        delete MergeTagMap[key];
    }

    MergeTagMap[newPatientWelcomeTag] = getNPWMergeTagText(formOrPackageName);

    if (setMergeTagMap) {
      setMergeTagMap({ ...MergeTagMap });
    } else {
      // eslint-disable-next-line no-console
      console.warn("setMergeTagMap is not provided. Skipping state update.");
    }

    dispatch(
      updateNewPatientWelcomeData({
        id: formOrPackageId,
        name: formOrPackageName,
        mergeTag: newPatientWelcomeTag,
        mergeTagDescription: getNPWMergeTagText(formOrPackageName),
      })
    );
  } else {
    for (const key in MergeTagMap) {
      if (key.includes("[[NPW-Form") || key.includes("[[NPW-Package"))
        delete MergeTagMap[key];
    }
    MergeTagMap[newPatientWelcomeTag] = getNPWMergeTagText(formOrPackageName);

    dispatch(
      updateNewPatientWelcomeData({
        id: formOrPackageId,
        name: formOrPackageName,
        mergeTag: newPatientWelcomeTag,
        mergeTagDescription: getNPWMergeTagText(formOrPackageName),
      })
    );
  }
};

//------------------------------Automated Forms-------------------------------//
export const AUTOMATED_FORMS = "Automated forms";
export const automatedFormsMergeTag = `[[AUTOMATED-FORMS | ${tagDescription} | ${AUTOMATED_FORMS}]]`;

export const automatedFormsTagIconHTML = `<img src=${mergeTagIcon} style="transform: translateY(3px);" alt="Automated Forms Icon" />`;

export const automatedFormsMergeTagText = `${tagDescription} ${automatedFormsTagIconHTML} ${AUTOMATED_FORMS}`;

export const prepareToSendAutomatedFormsMergeTagName = (
  body: string
): string => {
  const regex = new RegExp(tagDescription + ".*?" + AUTOMATED_FORMS);
  if (regex.test(body)) {
    return body?.replace(regex, automatedFormsMergeTag);
  }

  return body;
};
const parseCustomEmbedTags = (body: string) => {
  // Parse the HTML string into a DOM tree
  const parser = new DOMParser();
  const doc = parser.parseFromString(body, "text/html");

  // Find all elements with the class 'scheduling-merge-tag'
  const schedulingElements = doc.getElementsByClassName("scheduling-merge-tag");
  const reviewsElements = doc.getElementsByClassName("reviews-merge-tag");

  // Replace each element with the merge tag
  for (const element of schedulingElements) {
    element.outerHTML = USE_LINK_TO_SCHEDULE_MERGE_TAG;
  }
  for (const element of reviewsElements) {
    element.outerHTML = REVIEWS_REQUEST_TAG;
  }

  // Serialize the DOM tree back into an HTML string
  return doc.body.innerHTML;
};

export const sanitizeQuillContentToMergeTags = (
  body: string,
  isNewPatientWelcomeEnabled: boolean,
  npwMergeTagInfo: INewPatientWelcome
): string => {
  let sanitizedBody = removeHTML(parseCustomEmbedTags(body));
  Object.keys(MergeTagMap).forEach((mergeTagKey) => {
    const regex = new RegExp(MergeTagMap[mergeTagKey], "g");
    sanitizedBody = sanitizedBody.replace(regex, mergeTagKey);
  });
  if (isNewPatientWelcomeEnabled) {
    sanitizedBody = prepareToSendNPWMergeTagName(
      sanitizedBody,
      npwMergeTagInfo
    );
  }
  sanitizedBody = prepareToSendAutomatedFormsMergeTagName(sanitizedBody);
  return sanitizedBody;
};
