import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../../../../../global/helperFunctions/api/baseQueryWithReauth";

export const fastrAppApi = createApi({
  reducerPath: "fastrAppApi",
  baseQuery: baseQueryWithReAuth(
    `${process.env.REACT_APP_YAPI_DOCMGR}/api/v1/`
  ),
  endpoints: (builder) => ({
    requestFastrQRCodeLinkWithPin: builder.mutation({
      query: ({ pin }) => {
        return {
          url: `fastrapplinks/`,
          method: "POST",
          body: { pin },
        };
      },
    }),
  }),
});

export const { useRequestFastrQRCodeLinkWithPinMutation } = fastrAppApi;
