import { EFeatureFlags } from "../enums/EFeatureFlags";
import { useAppSelector } from "./useTypedRedux.hook";
import { useAuth0 } from "@auth0/auth0-react";
import { selectFeatureFlags } from "../services/feature-flags/featureFlagsSlice";
import { useGetFeatureFlagsQuery } from "../services/feature-flags/featureFlagsApi";
import { selectPracticeGuid } from "../../features/authentication/appSlice";

interface IFeatureFlag {
  isEnabled: boolean;
  isFetching: boolean;
  isError: boolean;
}

export const useGetFeatureFlag = (featureFlag: EFeatureFlags): IFeatureFlag => {
  const { isAuthenticated } = useAuth0();
  const guid = useAppSelector(selectPracticeGuid);
  const { isFetching, isError } = useGetFeatureFlagsQuery(null, {
    skip: !guid || !isAuthenticated,
  });

  const isEnabled = useAppSelector(selectFeatureFlags)?.includes(featureFlag);
  return {
    isEnabled,
    isFetching,
    isError,
  };
};
